@import url(https://fonts.googleapis.com/css?family=Montserrat);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {

  text-align: left;
 
}

.contactBackground
{
  position: relative;
    float:right;
    height: 100%;

-webkit-touch-callout: none;
-webkit-user-select: none;
-ms-user-select: none;
user-select: none;

}

.logoHeader
{
  position: fixed;
  width: 60vh;
  height: 10vh;
  top: 10vh;
  right: 5vh;
  max-width: 80%;

-webkit-touch-callout: none;
-webkit-user-select: none;
-ms-user-select: none;
user-select: none;

}
.contactDetails
{
  align-items:flex-start;
  font-family: 'Montserrat';
  color: white;
  position: fixed;
  width: 60vh;
  height: 10vh;
  top: 36vh;
  right: 5vh;
  max-width: 80%;

}

.background
{
 
  position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
    background-image: url(/static/media/varasto.b76abd39.jpg);
}

.App-header {

  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
 
  
  
}





