@import url('https://fonts.googleapis.com/css?family=Montserrat');

.App {

  text-align: left;
 
}

.contactBackground
{
  position: relative;
    float:right;
    height: 100%;

-webkit-touch-callout: none;
-webkit-user-select: none;
-khtml-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;

}

.logoHeader
{
  position: fixed;
  width: 60vh;
  height: 10vh;
  top: 10vh;
  right: 5vh;
  max-width: 80%;

-webkit-touch-callout: none;
-webkit-user-select: none;
-khtml-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;

}
.contactDetails
{
  align-items:flex-start;
  font-family: 'Montserrat';
  color: white;
  position: fixed;
  width: 60vh;
  height: 10vh;
  top: 36vh;
  right: 5vh;
  max-width: 80%;

}

.background
{
 
  position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
    background-image: url('./varasto.jpg');
}

.App-header {

  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
 
  
  
}




